var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"server-view"},[_c('transition',{attrs:{"name":"bubble"}},[(_vm.loading)?_c('base-loader'):_c('page-block',{staticClass:"server-view__block"},[_c('div',{staticClass:"addons"}),_c('base-table',{staticClass:"table-item__table",attrs:{"columns":_vm.tableHead,"list":_vm.addons}},_vm._l((_vm.addons),function(item){return _c('base-table-row',{key:item.id,attrs:{"item":item,"columns":_vm.tableHead},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('label',{staticClass:"standart-title"},[_vm._v(" "+_vm._s(_vm.$t(`${item.name}`))+" ")]),_c('label',{staticClass:"standart-text"},[_vm._v(" "+_vm._s(item.type ? `, ${item.type}` : '')+" ")]),(item.name === 'total')?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Включает в себя оперативную память, vCPU и диски',
                placement: 'top-start',
                container: false, //по другому не работает
              }),expression:"{\n                content: 'Включает в себя оперативную память, vCPU и диски',\n                placement: 'top-start',\n                container: false, //по другому не работает\n              }"}],staticClass:"base-input__hint",attrs:{"icon":"help","size":"small","color":"dim","tabindex":"-1"}}):_vm._e()]},proxy:true},(item.value)?{key:"value",fn:function(){return [_c('label',{staticClass:"standart-text"},[_vm._v(" "+_vm._s(item.name === 'os' ? item.value : _vm.$t(`values.${item.name}`, { n: item.value }))+" ")])]},proxy:true}:null,(item.price >= 0)?{key:"hour",fn:function(){return [_c('label',{staticClass:"standart-text"},[_vm._v(" "+_vm._s(_vm.$n(item.price, 'currency'))+" / час ")])]},proxy:true}:null,(item.price >= 0)?{key:"day",fn:function(){return [_c('label',{staticClass:"standart-text"},[_vm._v(" "+_vm._s(_vm.$n(item?.price * 24, 'currency'))+" / день ")])]},proxy:true}:null,(item.price >= 0)?{key:"month",fn:function(){return [_c('label',{staticClass:"standart-text"},[_vm._v(" "+_vm._s(_vm.$n(item?.price * 24 * 30, 'currency'))+" / месяц ")]),_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: '30 дней',
                placement: 'top-start',
                container: false, //по другому не работает
              }),expression:"{\n                content: '30 дней',\n                placement: 'top-start',\n                container: false, //по другому не работает\n              }"}],staticClass:"base-input__hint",attrs:{"icon":"help","size":"small","color":"dim","tabindex":"-1"}})]},proxy:true}:null],null,true)})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }