<template>
  <div class="server-view">
    <transition name="bubble">
      <base-loader v-if="loading" />
      <page-block v-else class="server-view__block">
        <div class="addons"></div>
        <base-table :columns="tableHead" :list="addons" class="table-item__table">
          <base-table-row v-for="item in addons" :key="item.id" :item="item" :columns="tableHead">
            <template #name>
              <label class="standart-title">
                {{ $t(`${item.name}`) }}
              </label>
              <label class="standart-text">
                {{ item.type ? `, ${item.type}` : '' }}
              </label>
              <plain-button
                v-if="item.name === 'total'"
                v-tooltip="{
                  content: 'Включает в себя оперативную память, vCPU и диски',
                  placement: 'top-start',
                  container: false, //по другому не работает
                }"
                icon="help"
                size="small"
                color="dim"
                tabindex="-1"
                class="base-input__hint"
              />
            </template>
            <template #value v-if="item.value">
              <label class="standart-text">
                {{ item.name === 'os' ? item.value : $t(`values.${item.name}`, { n: item.value }) }}
              </label>
            </template>
            <template #hour v-if="item.price >= 0">
              <label class="standart-text"> {{ $n(item.price, 'currency') }} / час </label>
            </template>
            <template #day v-if="item.price >= 0">
              <label class="standart-text"> {{ $n(item?.price * 24, 'currency') }} / день </label>
            </template>
            <template #month v-if="item.price >= 0">
              <label class="standart-text">
                {{ $n(item?.price * 24 * 30, 'currency') }} / месяц
              </label>
              <plain-button
                v-tooltip="{
                  content: '30 дней',
                  placement: 'top-start',
                  container: false, //по другому не работает
                }"
                icon="help"
                size="small"
                color="dim"
                tabindex="-1"
                class="base-input__hint"
              />
              <!--              <span class="base-checkbox__asterisk error-color">*</span>-->
            </template>
          </base-table-row>
        </base-table>
        <!--        </main-card>-->
      </page-block>
    </transition>
  </div>
</template>

<script>
// import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
// import MainLabel from '@/components/Labels/MainLabel.vue';
import BaseTableRow from '@/components/Table/BaseTableRow.vue';
import BaseTable from '@/components/Table/BaseTable.vue';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'ServerView',
  components: {
    BaseTable,
    BaseTableRow,
    // MainLabel,
    // MainCard,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'name',
          style: {
            width: '100px',
          },
        },
        {
          key: 'value',
          style: {
            width: '100px',
          },
        },
        {
          key: 'hour',
          style: {
            width: '100px',
          },
        },
        {
          key: 'day',
          style: {
            width: '100px',
          },
        },
        {
          key: 'month',
          style: {
            width: '100px',
          },
        },
      ],
      localDisk: null,
      loading: true,
    };
  },
  computed: {
    volumeId() {
      return this.tariff['os-extended-volumes:volumes_attached'].length > 0
        ? this.tariff['os-extended-volumes:volumes_attached'].map(x => x.id)
        : null;
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
    networkDisk() {
      const list = [];
      this.volumeId && this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId[0])
        ? this.volumeId.forEach(volumeId => {
            list.push(this.$store.state.moduleStack.volumes.filter(x => x.id === volumeId));
          })
        : null;
      return list.flat();
    },
    getVolume() {
      return this.$store.state.moduleStack.volumes.find(x => x.id === this.volumeId[0]) || '';
    },
    metadata() {
      return this.volumeId.length &&
        this.volumeId[0] &&
        this.getVolume.metadata &&
        Object.entries(this.getVolume.metadata).length
        ? this.getVolume.metadata
        : this.getVolume.volume_image_metadata;
    },
    osName() {
      return this.volumeId[0] && this.metadata ? this.metadata.image_name : null;
    },
    addons() {
      const specs = this.tariff.flavor;
      const os =
        this.tariff.image && this.$store.state.moduleStack.images
          ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id)
            ? this.$store.state.moduleStack.images.find(x => x.id === this.tariff.image.id).name
            : '---'
          : this.volumeId
          ? this.osName
          : '-----';
      let list = [];
      let sum = 0;
      if (os) list.push({ name: 'os', value: os });
      if (specs.name) list.push({ name: 'name', value: specs.name });
      if (specs.vcpus) {
        const countCpu = +specs.vcpus.toString();
        sum += +this.getCurrentPrice('vcpus') * countCpu.toFixed(2);
        list.push({
          name: 'cpu',
          value: countCpu,
          price: +this.getCurrentPrice('vcpus') * countCpu.toFixed(2),
        });
      }
      if (specs.ram) {
        const countRam = specs.ram.toString() / 1024;
        sum += +this.getCurrentPrice('memory') * countRam.toFixed(2);
        list.push({
          name: 'ram',
          value: countRam * 1024,
          price: +this.getCurrentPrice('memory') * countRam.toFixed(2),
        });
      }
      if (this.localDisk) {
        const countDisk = +specs.disk;
        sum += +this.getCurrentPrice('root_gb') * countDisk.toFixed(2);
        list.push({
          name: 'disk',
          value: countDisk,
          price: this.getCurrentPrice('root_gb') * countDisk.toFixed(2),
        });
      }
      if (this.networkDisk) {
        this.networkDisk.forEach(x => {
          let countNetworkDisk = +x.size;
          const item = `volume_${x.volume_type}`;
          sum += +this.getCurrentPrice(item) * countNetworkDisk.toFixed(2);
          list.push({
            name: 'networkDisk',
            value: countNetworkDisk,
            type: x.volume_type,
            price: this.getCurrentPrice(item) * countNetworkDisk.toFixed(2),
          });
        });
      }
      list.push({
        name: 'total',
        value: '',
        price: sum,
      });
      return list;
    },
  },
  watch: {
    loading: {
      handler: function (event) {
        // console.log(event);
        if (event) {
          this.$store
            .dispatch('moduleStack/getPrice', this.$attrs.id)
            .then(() => (this.loading = false));
        } else this.loading = false;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.localDisk === null) this.getLocalDisk();
    // if (!this.getPrice) this.$store.dispatch('moduleStack/getPrice', this.$attrs.id);
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    getCurrentPrice(item) {
      // return this.getPrice.find(x => x.intname === item)?.stat.toFixed(2) === 0
      // return this.getPrice.find(x => x.intname === item)?.stat === 0
      //   ? 0
      // : // : +this.getPrice.find(x => x.intname === item)?.[dev].toFixed(2);
      return +this.getPrice.find(x => x.intname === item)?.[dev];
    },
    getLocalDisk() {
      this.$store
        .dispatch('moduleStack/isLocalDisk', { id: this.$attrs.id, uuid: this.tariff.id })
        .then(async data => {
          if (data && data.result === 'ok') {
            this.localDisk = data.existslocaldisk;
          }
        })
        .catch(e => console.log(e));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "values": {
        "cpu": "{n} шт.",
        "ram": "{n} Мб.",
        "disk": "{n} Гб.",
        "networkDisk": "{n} Гб."

      },
      "os": "Источник",
      "name": "Тип конфигурации",
      "total": "Общая цена",


      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.server-view__block{
  margin-top : -0.5rem;
}
.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {

    +breakpoint(xl-and-up) {
      flex: 0 0 12rem;
      margin-right: 0.5rem;
    }
    +breakpoint(sm-and-up) {
      flex: 0 0 8rem;
      margin-right: 0.5rem;
    }
  }
  &__value {
    +breakpoint(xl-and-up) {
      flex: 0 0 12rem;
      margin-right: 0.5rem;
    }
    +breakpoint(sm-and-up) {
      flex: 0 0 8rem;
      margin-right: 0.5rem;
    }
  }
  &__price {
    +breakpoint(xl-and-up) {
      flex: 0 0 12rem;
      margin-right: 0.5rem;
    }
    +breakpoint(sm-and-up) {
      flex: 0 0 8rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
